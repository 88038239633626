.login-form {
  max-width: 500px;
  margin: 2rem auto;
  padding: 2rem;
  border-radius: 5px;
}

.login-form label {
  display: block;
  padding: 1rem 0 0.25rem;
  text-transform: uppercase;
  font-size: 14px;
}

.login-form input {
  display: block;
  width: 100%;
  border: 2px solid rgb(204, 208, 212);
  padding: 0.5rem;
  font-size: 18px;
  border-radius: 5px;
}

.login-form input:focus {
  border-color: #186184;
}

.login-form h3 {
  font-size: 22px;
  font-weight: 400;
}

.login-form p {
  font-size: 12px; 
  margin: 2.5em 0; 
}

.message {
  font-size: 18px !important;  
}

.forgot-password {
  font-size: 18px;
  color: #0071bb;
  text-decoration: underline;
  background-color: inherit;
  border: none;
  margin: 1rem 0;
  padding: 0rem;
  
}

.forgot-password:hover {
  color: #344050;
}

.sendBtn {
  border: 0;
  background-color:#186184;
  padding: .5rem 2.0rem;
  color: white;
  margin: 1rem 0;
  width: auto;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: block;
  font-size: 18px;  
}

.sendBtn:hover {
  background-color: #6cc9f3;
}

.check-label {
  font-family: system-ui, sans-serif;
  font-size: 2rem;  
  line-height: 1.1;
  display: grid !important;
  grid-template-columns: 1.5em auto;
  gap: 0.5em;    
}

.check-label input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;  
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid currentColor;
  border-radius: 0.15em;
  transform: translateY(-0.18em);  
  display: grid;
  place-content: center;
}

.check-label input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  /* Windows High Contrast Mode */
  background-color: CanvasText;
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

.check-label input[type="checkbox"]:focus {
    outline: max(2px, 0.15em) solid currentColor;
    outline-offset: max(2px, 0.15em);
  }

.check-label input[type="checkbox"]:checked::before {
    transform: scale(1);
  }
