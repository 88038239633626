#container {
    width: 100%;
    background: #FFFF;
    float: left;    
    margin-right: -500px;
  }
  #content {
    margin-right: 500px;
    position: absolute;    
    width: auto;
    top: 0px;
    bottom:0px;
    left: 0px;
    right: 0px;
    overflow: hidden;
  }

  #contentframe {
    border: 4px solid #186184;  
    height: 100%;    
  }
  
  #imagebox img {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;    
    max-width: 90%;
    min-width: 90%;
  }
  
  #sidebar {
    width: 500px;
    float: right;
    padding: 50px 60px 0px;
    position: relative;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;        
  } 

  #footer {
    position: absolute;
    box-sizing: border-box;
    bottom: 0px;
    right: 0px;
    left: 0px;
    background-color: rgb(24, 97, 132);
    padding: 30px 60px;
    font-weight: 300;
    min-width: 680px;
  }

  #footer h4 {
    color: rgba(255,255,255,0.8);    
    font-size: 18px;
    font-weight: 300;
    box-sizing: border-box;
    line-height: 19.8px;
    text-size-adjust: 100%;
    margin-top: 20px;
    margin-bottom: 10px;    
  }

  #footer p {
    color: #999;
    font-size: 13px;
    display: block;
    margin: 0 0 10px;
    box-sizing: border-box;    
  }