* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Montserrat",Helvetica,Arial,sans-serif;  
}

#App {
  font-size: 13px;
  color: #707478;
  background-color: #ffffff; 
}